import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import successIcon from 'assets/Images/icons/success.svg';

import './style.scss';
import Button from '../Button';
import Typography from '@material-ui/core/Typography';

interface IProps {
  title: string;
  showSuccess: boolean;
  setShowSuccess: (show: boolean) => void;
}

function SuccessDialog(props: IProps) {
  const {
    showSuccess,
    setShowSuccess,
    title,
  } = props;

  const handleClose = () => {
    setShowSuccess(false);
  };
  return (
    <div>
      <Dialog
        maxWidth="md"
        open={showSuccess}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        style={{ padding: '2%' }}
      >
        <DialogContent className="form-dialog-content">
          <div className="successIcon">
            <img src={successIcon} alt="success" />
          </div>
          <Typography variant="h4" className="successTitle">
            {title}
          </Typography>
          <Button
            type="submit"
            variant="primary"
            onClick={() => handleClose()}
            classes="closeButton"
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SuccessDialog;
