export const toastStyle = {
  duration: 4000,
  success: {
    style: {
      background: 'green',
      padding: '10px 20px',
      color: 'white',
    },
  },
  error: {
    style: {
      background: 'red',
      padding: '10px 20px',
      color: 'white',
    },
  },
};
