import Http from './Http';
import {
  ILoginResponse,
  ILoginInput,
  IUserCreate,
  IChangePassword,
  IResetPassword,
} from 'common/interfaces/IAuth';
import {
  IAccountActivate,
  IAccountActivateWithCode,
} from '../interfaces/IUser';

const http = new Http();

export default class Auth {
  static login(apiURL: string, args: ILoginInput) {
    return http.post<ILoginResponse>({
      endpoint: `/${apiURL}/accounts/auth/obtain/`,
      payload: args,
    });
  }

  static createUser(apiURL: string, args: IUserCreate) {
    return http.post<any>({
      endpoint: `/${apiURL}/users/onboard/`,
      payload: args,
    });
  }
  static createNormalUser(apiURL: string, args: IUserCreate) {
    return http.post<any>({
      endpoint: `/${apiURL}/users/normal-user/`,
      payload: args,
    });
  }
  static patchUser(
    apiURL: string,
    args: IUserCreate,
    username: string,
  ) {
    return http.patch<any>({
      endpoint: `/${apiURL}/users/${username}/`,
      payload: args,
    });
  }

  static changePassword(
    apiURL: string,
    args: IChangePassword,
    username: string,
  ) {
    return http.post<any>({
      endpoint: `/${apiURL}/users/${username}/password/`,
      payload: args,
    });
  }

  static getUser(apiURL: string, username: string) {
    return http.get<any>({
      endpoint: `/${apiURL}/users/${username}/`,
    });
  }
  static retrieveUser(apiURL: string, username: string) {
    return http.get<any>({
      endpoint: `/${apiURL}/users/${username}/profile`,
    });
  }
  static getUsers(apiURL: string, filter: any) {
    return http.get<any>({
      endpoint: `/${apiURL}/users/`,
      payload: { params: filter },
    });
  }

  static patchUserStatus(
    apiURL: string,
    args: any,
    username: string,
  ) {
    return http.patch<any>({
      endpoint: `/${apiURL}/users/${username}/status/`,
      payload: args,
    });
  }

  static otpRequest(apiURL: string, email: string) {
    return http.post<any>({
      endpoint: `/${apiURL}/accounts/password/reset/`,
      payload: {
        email,
      },
    });
  }
  static resetPassword(apiURL: string, args: IResetPassword) {
    return http.post<any>({
      endpoint: `/${apiURL}/accounts/password/reset/confirm/`,
      payload: args,
    });
  }

  static resetResendOTP(apiURL: string, email: string) {
    return http.post<any>({
      endpoint: `/${apiURL}/accounts/password-reset/resend/activation-code/`,
      payload: {
        email,
      },
    });
  }

  static activateAccountWithCode(
    code: string,
    args: IAccountActivateWithCode,
  ) {
    return http.post<any>({
      endpoint: `/lvl/users/onboard/activate/${code}/`,
      payload: args,
    });
  }

  static activateAccount(
    activation_token: string,
    args: IAccountActivate,
  ) {
    return http.post<any>({
      endpoint: `/lvl/users/onboard/activate/`,
      payload: args,
      config: {
        headers: {
          'X-ACTIVATION-TOKEN': activation_token,
        },
      },
    });
  }

  static postUserProfileImage(
    apiURL: string,
    username: string,
    userImage: any,
  ) {
    return http.post<any>({
      endpoint: `/${apiURL}/users/${username}/profile-picture/`,
      payload: userImage,
      config: {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
      },
    });
  }
  static updateProfile(
    apiURL: string,
    username: string,
    data: any,
  ) {
    return http.post<any>({
      endpoint: `/${apiURL}/users/${username}/profile/`,
      payload: data,
      // config: {
      //   headers: {
      //     'Content-Type': `multipart/form-data`,
      //   },
      // },
    });
  }
  static softDeleteUser(
    apiURL: string,
    username: string,
  ){
    return http.patch<any>({
      endpoint: `/${apiURL}/users/${username}/delete-user/`
    });
  }
}