import React, { useEffect, useState } from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { InputAdornment, TextField } from '@material-ui/core';

import { useStyles } from './styles';

interface IProps {
  title: string;
  placeholder: string;
  validation?: any;
  register: any;
  icon?: any;
  type?: string;
}

const FormInput = (props: IProps) => {
  const { title, placeholder, validation, register, icon, type } =
    props;
  const [show, setShow] = useState<boolean>(false);
  const [formType, setFormType] = useState<string>('');
  const [inputProps, setInputProps] = useState({});
  const styles = useStyles();

  useEffect(() => {
    if (type) {
      if (type === 'password') {
        setInputProps({
          startAdornment: (
            <InputAdornment position="start">
              <img src={icon} alt={title} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              className={styles.passwordVisibility}
              onClick={() => {
                setShow(!show);
              }}
            >
              {show ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </InputAdornment>
          ),
        });
        if (show) {
          setFormType('text');
        } else {
          setFormType('password');
        }
      } else {
        setInputProps({
          startAdornment: (
            <InputAdornment position="start">
              <img src={icon} alt={title} />
            </InputAdornment>
          ),
        });
        setFormType(type);
      }
    } else {
      setInputProps({
        startAdornment: (
          <InputAdornment position="start">
            <img src={icon} alt={title} />
          </InputAdornment>
        ),
      });
      setFormType('text');
    }
  }, [show, type, title, icon, styles.passwordVisibility]);

  return (
    <div>
      <TextField
        placeholder={placeholder}
        name={title}
        id={`id-${title}`}
        fullWidth
        InputProps={inputProps}
        variant="outlined"
        type={formType}
        autoComplete="off"
        className={styles.formInputStyle}
        {...register(title, validation)}
      />
    </div>
  );
};

export default FormInput;
