import { lazy } from 'react';

import * as paths from 'common/constants/routes';

const routes = {
  dashboard: {
    path: paths.DASHBOARD,
    component: lazy(() => import('pages/Dashboard')),
  },
  home: {
    path: paths.HOME,
    component: lazy(() => import('pages/Home')),
  },
  login: {
    path: paths.LOGIN,
    component: lazy(() => import('pages/Login')),
  },
  resetPassword: {
    path: paths.RESET,
    component: lazy(() => import('pages/PasswordReset')),
  },
  activateAccountWithCode: {
    path: paths.ACTIVATEWITHCODE,
    component: lazy(() => import('pages/AccountActivateWithCode')),
  },
  activateAccount: {
    path: paths.ACTIVATE,
    component: lazy(() => import('pages/AccountActivate')),
  },
  activityLogs: {
    path: paths.activityLog,
    component: lazy(() => import('pages/ActivityLog')),
  },
  courses: {
    path: paths.COURSES,
    component: lazy(() => import('pages/Courses')),
  },
  cohort: {
    path: paths.COHORT,
    component: lazy(() => import('pages/Cohort')),
  },
  addCohort: {
    path: paths.ADDCOHORT,
    component: lazy(() => import('pages/Cohort/CohortAdd')),
  },
  users: {
    path: paths.USERS,
    component: lazy(() => import('pages/Users')),
  },
  groups: {
    path: paths.GROUPS,
    component: lazy(() => import('pages/Groups')),
  },
  assignments: {
    path: paths.ASSIGNMENTS,
    component: lazy(() => import('pages/Assignments')),
  },
  marketing_contents: {
    path: paths.MARKETING_CONTENT,
    component: lazy(() => import('pages/MarketingContents')),
  },
  assignmentAdd: {
    path: paths.ASSIGNMENTADD,
    component: lazy(
      () => import('pages/Assignments/CreateAssignment'),
    ),
  },
  assignmentEdit: {
    path: paths.ASSIGNMENTEDIT,
    component: lazy(
      () => import('pages/Assignments/UpdateAssignment'),
    ),
  },
  assignmentDetail: {
    path: paths.ASSIGNMENT_DETAIL,
    component: lazy(() => import('pages/Assignments/ViewAssignment')),
  },
  project: {
    path: paths.PROJECT,
    component: lazy(() => import('pages/Projects')),
  },
  projectDetail: {
    path: paths.PROJECT_DETAIL,
    component: lazy(() => import('pages/Projects/ProjectDetail')),
  },
  skills: {
    path: paths.SKILLS,
    component: lazy(() => import('pages/Skills')),
  },
  skillDetail: {
    path: paths.SKILL_DETAIL,
    component: lazy(() => import('pages/Skills/SkillDetail')),
  },
  topics: {
    path: paths.TOPICS,
    component: lazy(() => import('pages/Topics')),
  },

  career: {
    path: paths.CAREER,
    component: lazy(() => import('pages/Career')),
  },
  careerDetail: {
    path: paths.CAREERDETAIL,
    component: lazy(() => import('pages/Career/JobDetail')),
  },
  jobApplication: {
    path: paths.JOBAPPLICATION,
    component: lazy(() => import('pages/Career/Application')),
  },
  createCareer: {
    path: paths.CREATECAREER,
    component: lazy(() => import('pages/Career/CreateCareer')),
  },
  editCareer: {
    path: paths.CAREEREDIT,
    component: lazy(() => import('pages/Career/EditCareer')),
  },
  questions: {
    path: paths.QUESTIONS,
    component: lazy(() => import('pages/Questions')),
  },
  createQuestion: {
    path: paths.CREATE_QUESTION,
    component: lazy(() => import('pages/Questions/QuestionAdd')),
  },
  editQuestion: {
    path: paths.EDIT_QUESTION,
    component: lazy(() => import('pages/Questions/QuestionEdit')),
  },
  viewQuestion: {
    path: paths.VIEW_QUESTION,
    component: lazy(() => import('pages/Questions/QuestionView')),
  },
  videos: {
    path: paths.VIDEOS,
    component: lazy(() => import('pages/Videos')),
  },

  documents: {
    path: paths.DOCUMENTS,
    component: lazy(() => import('pages/Documents')),
  },
  articles: {
    path: paths.ARTICLES,
    component: lazy(() => import('pages/Articles')),
  },
  feedbacks: {
    path: paths.FEEDBACKS,
    component: lazy(() => import('pages/Feedbacks')),
  },
  createContentQuestion: {
    path: paths.ADD_CONTENT_QUESTION,
    component: lazy(
      () => import('pages/Content/Questions/CreateQuestion'),
    ),
  },
  editContentQuestion: {
    path: paths.EDIT_CONTENT_QUESTION,
    component: lazy(
      () => import('pages/Content/Questions/UpdateQuestion'),
    ),
  },
  videoItem: {
    path: paths.VIDEOITEM,
    component: lazy(() => import('pages/Videos/Item')),
  },
  videoQuestion: {
    path: paths.VIDEOQUESTIONS,
    component: lazy(() => import('pages/Videos/Questions')),
  },
  documentItem: {
    path: paths.DOCUMENTITEM,
    component: lazy(() => import('pages/Documents/Item')),
  },
  documentQuestion: {
    path: paths.DOCUMENTQUESTIONS,
    component: lazy(() => import('pages/Documents/Questions')),
  },
  articleItem: {
    path: paths.ARTICLEITEM,
    component: lazy(() => import('pages/Articles/Item')),
  },
  articleQuestion: {
    path: paths.ARTICLEQUESTIONS,
    component: lazy(() => import('pages/Articles/Questions')),
  },
  courseDetail: {
    path: paths.VIEWCOURSE,
    component: lazy(() => import('pages/Courses/CourseView')),
  },
  editCohort: {
    path: paths.EDITCOHORT,
    component: lazy(() => import('pages/Cohort/CohortEdit')),
  },
  cohortPlanner: {
    path: paths.COHORTPLANNER,
    component: lazy(() => import('pages/Cohort/CohortPlanner')),
  },
  cohortDetail: {
    path: paths.VIEWCOHORT,
    component: lazy(() => import('pages/Cohort/CohortView')),
  },
  editProject: {
    path: paths.EDITPROJECT,
    component: lazy(() => import('pages/Projects/ProjectEdit')),
  },
  addProject: {
    path: paths.ADDPROJECT,
    component: lazy(() => import('pages/Projects/ProjectAdd')),
  },
  addVideo: {
    path: paths.ADDVIDEOS,
    component: lazy(() => import('pages/Videos/CreateVideo')),
  },
  addDocument: {
    path: paths.ADDDOCUMENTS,
    component: lazy(() => import('pages/Documents/CreateDocument')),
  },
  addArticles: {
    path: paths.ADDARTICLES,
    component: lazy(() => import('pages/Articles/CreateArticle')),
  },
  editContent: {
    path: paths.EDITCONTENT,
    component: lazy(() => import('pages/Content')),
  },
  application: {
    path: paths.APPLICATION,
    component: lazy(() => import('pages/Applications')),
  },
  module: {
    path: paths.MODULES,
    component: lazy(() => import('pages/Module')),
  },
  addModule: {
    path: paths.ADD_MODULE,
    component: lazy(() => import('pages/Module/AddModule')),
  },
  moduleEdit: {
    path: paths.MODULE_EDIT,
    component: lazy(() => import('pages/Module/EditModule')),
  },
  moduleContentEdit: {
    path: paths.MODULE_CONTENT_EDIT,
    component: lazy(() => import('pages/Module/ModuleContentManage')),
  },
  moduleDetail: {
    path: paths.MODULE_DETAIL,
    component: lazy(() => import('pages/Module/ModuleDetail')),
  },
  applicationViewDetail: {
    path: paths.APPLICATION_DETAIL,
    component: lazy(
      () => import('pages/Applications/ApplicationView'),
    ),
  },
  aptitudeQuestion: {
    path: paths.APTITUDE_QUESTION,
    component: lazy(() => import('pages/AptitudeQuestion')),
  },
  createAptitudeQuestion: {
    path: paths.ADD_APTITUDE_QUESTION,
    component: lazy(
      () => import('pages/AptitudeQuestion/AptitudeQuestionAdd'),
    ),
  },
  editAptitudeQuestion: {
    path: paths.APTITUDE_QUESTION_EDIT,
    component: lazy(
      () => import('pages/AptitudeQuestion/AptitudeQuestionEdit'),
    ),
  },
  viewAptitudeQuestion: {
    path: paths.APTITUDE_QUESTION_DETAIL,
    component: lazy(
      () => import('pages/AptitudeQuestion/AptitudeQuestionView'),
    ),
  },
  aptitudeTest: {
    path: paths.APTITUDE_TEST,
    component: lazy(() => import('pages/AptitudeTest')),
  },
  createAptitudeTest: {
    path: paths.ADD_APTITUDE_TEST,
    component: lazy(
      () => import('pages/AptitudeTest/AptitudeTestAdd'),
    ),
  },
  editAptitudeTest: {
    path: paths.APTITUDE_TEST_EDIT,
    component: lazy(
      () => import('pages/AptitudeTest/AptitudeTestEdit'),
    ),
  },
  viewAptitudeTest: {
    path: paths.APTITUDE_TEST_DETAIL,
    component: lazy(
      () => import('pages/AptitudeTest/AptitudeTestView'),
    ),
  },
  profile: {
    path: paths.PROFILE,
    component: lazy(() => import('pages/Users/Profile')),
  },
  editProfile: {
    path: paths.EDIT_PROFILE,
    component: lazy(() => import('pages/Users/EditProfile')),
  },
};

export default routes;
