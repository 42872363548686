import { makeStyles, createStyles, Theme } from '@material-ui/core';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      overflow: 'hidden',
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      minHeight: '100vh',
      overflow: 'auto',
      padding: `0 ${theme.spacing}px`,
      backgroundColor: '#ffffff',
    },
    appBarSpacer: theme.mixins.toolbar,
    title: {
      flex: 1,
      textAlign: 'left',
    },
  }),
);

export { useStyles };
