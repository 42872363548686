import { ReactChild } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import './style.scss';

interface IProps {
  children: ReactChild;
  title: string;
  show: boolean;
  setShow: (show: boolean) => void;
  hideTitle?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  className?: any;
  isScrollBody?: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function FormDialog(props: IProps) {
  const {
    show,
    setShow,
    title,
    children,
    hideTitle = false,
    size = 'md',
    isScrollBody,
  } = props;

  const handleClose = () => {
    setShow(false);
  };
  return (
    <div>
      <Dialog
        maxWidth={size}
        open={show}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        scroll={isScrollBody === false ? 'paper' : 'body'}
      >
        {!hideTitle && (
          <DialogTitle onClose={handleClose} id="form-dialog-title">
            {title}
          </DialogTitle>
        )}
        <DialogContent dividers>{children}</DialogContent>
      </Dialog>
    </div>
  );
}

export default FormDialog;
