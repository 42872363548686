import { BrowserRouter as Router, Switch } from 'react-router-dom';
import routes from '../config/routes';
import AuthenticatedRoute from 'common/components/Routes/Authenticated';
import UnauthenticatedRoute from 'common/components/Routes/Unauthenticated';
import config from '../config';
import { useCurrentUser } from 'common/context/CurrentUser';

const admin = [config.roles.Admin];
const creator = [config.roles.Creator];
const common = [config.roles.Admin, config.roles.Creator];

const Routes = () => {
  const { currentUser } = useCurrentUser();
  let group: string = currentUser?.groups[0];

  return (
    <Router>
      <Switch>
        <UnauthenticatedRoute
          path={routes.login.path}
          component={routes.login.component}
        />
        <UnauthenticatedRoute
          path={routes.resetPassword.path(':email')}
          component={routes.resetPassword.component}
        />
        <UnauthenticatedRoute
          path={routes.activateAccountWithCode.path(
            ':userCode',
            ':code',
          )}
          component={routes.activateAccountWithCode.component}
        />
        <UnauthenticatedRoute
          path={routes.activateAccount.path(':activation_token')}
          component={routes.activateAccount.component}
        />
        <AuthenticatedRoute
          path={routes.activityLogs.path}
          Component={routes.activityLogs.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.home.path}
          Component={
            group === admin[0]
              ? routes.courses.component
              : routes.questions.component
          }
          exact={true}
          roles={common}
        />
        <AuthenticatedRoute
          path={routes.courses.path}
          Component={routes.courses.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.marketing_contents.path}
          Component={routes.marketing_contents.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.dashboard.path}
          Component={routes.dashboard.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.cohort.path}
          Component={routes.cohort.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.cohortDetail.path(':id')}
          Component={routes.cohortDetail.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.users.path}
          Component={routes.users.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.groups.path}
          Component={routes.groups.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.assignments.path}
          Component={routes.assignments.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.assignmentAdd.path}
          Component={routes.assignmentAdd.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.assignmentEdit.path(':id')}
          Component={routes.assignmentEdit.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.assignmentDetail.path(':id')}
          Component={routes.assignmentDetail.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.career.path}
          Component={routes.career.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.createCareer.path}
          Component={routes.createCareer.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.careerDetail.path(':id')}
          Component={routes.careerDetail.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.jobApplication.path(':id')}
          Component={routes.jobApplication.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.editCareer.path(':id')}
          Component={routes.editCareer.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.project.path}
          Component={routes.project.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.projectDetail.path(':id')}
          Component={routes.projectDetail.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.skills.path}
          Component={routes.skills.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.skillDetail.path(':slug')}
          Component={routes.skillDetail.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.topics.path}
          Component={routes.topics.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.addCohort.path}
          Component={routes.addCohort.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.questions.path}
          Component={routes.questions.component}
          exact={true}
          roles={creator}
        />
        <AuthenticatedRoute
          path={routes.createQuestion.path}
          Component={routes.createQuestion.component}
          exact={true}
          roles={creator}
        />
        <AuthenticatedRoute
          path={routes.editQuestion.path(':id')}
          Component={routes.editQuestion.component}
          exact={true}
          roles={creator}
        />
        <AuthenticatedRoute
          path={routes.viewQuestion.path(':id')}
          Component={routes.viewQuestion.component}
          exact={true}
          roles={creator}
        />
        <AuthenticatedRoute
          path={routes.createContentQuestion.path(':id')}
          Component={routes.createContentQuestion.component}
          exact={true}
          roles={creator}
        />
        <AuthenticatedRoute
          path={routes.editContentQuestion.path(':question_id')}
          Component={routes.editContentQuestion.component}
          exact={true}
          roles={creator}
        />
        <AuthenticatedRoute
          path={routes.videos.path}
          Component={routes.videos.component}
          exact={true}
          roles={creator}
        />
        <AuthenticatedRoute
          path={routes.documents.path}
          Component={routes.documents.component}
          exact={true}
          roles={creator}
        />
        <AuthenticatedRoute
          path={routes.articles.path}
          Component={routes.articles.component}
          exact={true}
          roles={creator}
        />
        <AuthenticatedRoute
          path={routes.feedbacks.path}
          Component={routes.feedbacks.component}
          exact={true}
          roles={admin}
        />

        <AuthenticatedRoute
          path={routes.videoItem.path(':id')}
          Component={routes.videoItem.component}
          exact={true}
          roles={creator}
        />
        <AuthenticatedRoute
          path={routes.videoQuestion.path(':id')}
          Component={routes.videoQuestion.component}
          exact={true}
          roles={creator}
        />

        <AuthenticatedRoute
          path={routes.documentItem.path(':id')}
          Component={routes.documentItem.component}
          exact={true}
          roles={creator}
        />
        <AuthenticatedRoute
          path={routes.documentQuestion.path(':id')}
          Component={routes.documentQuestion.component}
          exact={true}
          roles={creator}
        />
        <AuthenticatedRoute
          path={routes.articleItem.path(':id')}
          Component={routes.articleItem.component}
          exact={true}
          roles={creator}
        />
        <AuthenticatedRoute
          path={routes.articleQuestion.path(':id')}
          Component={routes.articleQuestion.component}
          exact={true}
          roles={creator}
        />

        <AuthenticatedRoute
          path={routes.editCohort.path(':id')}
          Component={routes.editCohort.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.cohortPlanner.path(':id')}
          Component={routes.cohortPlanner.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.courseDetail.path(':id')}
          Component={routes.courseDetail.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.editProject.path(':id')}
          Component={routes.editProject.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.addProject.path}
          Component={routes.addProject.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.addVideo.path}
          Component={routes.addVideo.component}
          exact={true}
          roles={creator}
        />
        <AuthenticatedRoute
          path={routes.addDocument.path}
          Component={routes.addDocument.component}
          exact={true}
          roles={creator}
        />
        <AuthenticatedRoute
          path={routes.addArticles.path}
          Component={routes.addArticles.component}
          exact={true}
          roles={creator}
        />
        <AuthenticatedRoute
          path={routes.editContent.path(':id')}
          Component={routes.editContent.component}
          exact={true}
          roles={creator}
        />
        <AuthenticatedRoute
          path={routes.application.path}
          Component={routes.application.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.module.path}
          Component={routes.module.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.addModule.path}
          Component={routes.addModule.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.moduleEdit.path(':id')}
          Component={routes.moduleEdit.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.moduleContentEdit.path(':id')}
          Component={routes.moduleContentEdit.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.moduleDetail.path(':id')}
          Component={routes.moduleDetail.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.applicationViewDetail.path(
            ':courseUuid',
            ':id',
          )}
          Component={routes.applicationViewDetail.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.aptitudeQuestion.path}
          Component={routes.aptitudeQuestion.component}
          exact={true}
          roles={creator}
        />
        <AuthenticatedRoute
          path={routes.createAptitudeQuestion.path}
          Component={routes.createAptitudeQuestion.component}
          exact={true}
          roles={creator}
        />
        <AuthenticatedRoute
          path={routes.editAptitudeQuestion.path(':id')}
          Component={routes.editAptitudeQuestion.component}
          exact={true}
          roles={creator}
        />
        <AuthenticatedRoute
          path={routes.viewAptitudeQuestion.path(':id')}
          Component={routes.viewAptitudeQuestion.component}
          exact={true}
          roles={creator}
        />
        <AuthenticatedRoute
          path={routes.aptitudeTest.path}
          Component={routes.aptitudeTest.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.createAptitudeTest.path}
          Component={routes.createAptitudeTest.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.editAptitudeTest.path(':id')}
          Component={routes.editAptitudeTest.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.viewAptitudeTest.path(':id')}
          Component={routes.viewAptitudeTest.component}
          exact={true}
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.profile.path(':id')}
          Component={routes.profile.component}
          exact={true}
          roles={common}
        />
        <AuthenticatedRoute
          path={routes.editProfile.path(':id')}
          Component={routes.editProfile.component}
          exact={true}
          roles={common}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
