import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import StarIcon from '@material-ui/icons/Star';
import List from '@material-ui/core/List';
import classNames from 'classnames';
import TitleData from './TitleData';

import { useStyles } from './styles';
import { useCurrentUser } from '../../context/CurrentUser';

interface Props {
  isDrawerOpen: boolean;
  handleToggleDrawer: any;
}

const NavBar = (props: Props) => {
  const { isDrawerOpen, handleToggleDrawer } = props;
  const classes = useStyles();
  const { currentUser } = useCurrentUser();
  return (
    <>
      <Hidden smDown>
        <Drawer
          variant="permanent"
          open
          classes={{
            paper:
              currentUser?.groups?.[0] === 'Creator'
                ? classes.drawerPaperCreator
                : classes.drawerPaper,
          }}
        >
          <List>
            <h3 style={{ paddingLeft: '10%' }}> MENU </h3>

            <TitleData />
          </List>
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor="left"
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !isDrawerOpen && classes.drawerPaperClose,
            ),
          }}
          open={isDrawerOpen}
          onClose={handleToggleDrawer}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <div className={classes.toolbar}>
            <div className={classes.pageTitleContainer}>
              <div>
                <StarIcon />
              </div>
              <Typography
                variant="h6"
                className={classes.pageTitleTypography}
              >
                Menu
              </Typography>
            </div>
          </div>
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
