import {Checkbox, FormControlLabel} from "@material-ui/core";
import React, {useState} from "react";
import {toast} from "react-hot-toast";
import { useForm } from 'react-hook-form';

import passwordIcon from "assets/Images/icons/password_icon.svg";
import confirmPasswordIcon from "assets/Images/icons/confirm_password.svg";
import Button from 'common/components/Button';
import Auth from "common/services/UserService";
import {Levelup} from "common/constants/api";
import FormInput from "common/components/FormInput";
import './style.scss';

interface Props {
    setShow: Function;
    setShowPassword: Function;
}

const ChangePassword = (props: Props) => {
    const { setShow, setShowPassword } = props;
    const { register, handleSubmit, setValue, formState:{errors} } = useForm({
        mode: 'onChange',
        shouldFocusError: true,
    });
    const [saving, setSaving] = useState<boolean>(false);

    const validationData = {
        required: 'This field is required.',
        minLength: {
            value: 10,
            message:
                'Password must not be less than 10 characters.',
        },
        maxLength: {
            value: 128,
            message:
                'Password must not be more than 128 characters.',
        },
    };

    const handleCheckBox = (event: any) => {
        setValue('logout_from_other_devices', event.target.checked);
    }

    const onSubmit = (data: any) => {
        setSaving(true);
        if (!data.logout_from_other_devices) {
            setValue('logout_from_other_devices', false);
        }
        Auth.changePassword(Levelup, data, 'me').then((response) => {
            setSaving(false);
            setShow(false);
            setShowPassword(true);
        }).catch(() => {
            toast.error("Password Change Unsuccessful!");
            setSaving(false);
        });
    };

    return (
        <div className="container">
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormInput
                    title="old_password"
                    placeholder="Old Password"
                    validation={validationData}
                    register={register}
                    type="password"
                    icon={passwordIcon}
                />
                {errors?.old_password && (
                    <p className="error-message">{errors?.old_password?.message}</p>
                )}
                <br />
                <FormInput
                    title="password"
                    placeholder="New Password"
                    validation={validationData}
                    register={register}
                    type="password"
                    icon={passwordIcon}
                />
                {errors?.password && (
                    <p className="error-message">{errors?.password?.message}</p>
                )}
                <br />
                <FormInput
                    title="repeat_password"
                    placeholder="Confirm Password"
                    validation={validationData}
                    register={register}
                    type="password"
                    icon={confirmPasswordIcon}
                />
                {errors?.repeat_password && (
                    <p className="error-message">{errors?.repeat_password?.message}</p>
                )}
                <br />
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={handleCheckBox}
                            name="logout_from_other_devices"
                            color="primary"
                        />
                    }
                    label="Logout from other devices"
                />
                <br/>
                <div className="submit-group">
                    <Button
                        type="cancel"
                        variant=""
                        onClick={() => setShow(false)}
                    >
                        Cancel
                    </Button>
                    &nbsp;
                    <Button type="submit"
                            disabled={saving}
                            variant="primary"
                            onClick={handleSubmit(onSubmit)}>
                        {saving? 'Processing...' :'Submit'}
                    </Button>
                </div>
                <br />
            </form>
        </div>
    );
};

export default ChangePassword;
