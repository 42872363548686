import { NavLink, useLocation } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import routes from 'config/routes';
import { useStyles } from './styles';
import config from '../../../config';
import cohortIcon from '../../../assets/Images/svg/cohortsicon.svg';
import projectIcon from '../../../assets/Images/svg/projecticon.svg';
import skillsIcon from '../../../assets/Images/svg/skillsicon.svg';
import dashboardIcon from '../../../assets/Images/svg/dashboardicon.svg';
import activitylogIcon from '../../../assets/Images/svg/activitylogicon.svg';
import coursesIcon from '../../../assets/Images/svg/coursesicon.svg';
import applicationIcon from '../../../assets/Images/svg/applicationicon.svg';
import usersIcon from '../../../assets/Images/svg/usersicon.svg';
import groupsIcon from '../../../assets/Images/svg/groupsicon.svg';
import assignmentIcon from '../../../assets/Images/svg/assignmenticon.svg';
import topicsIcon from '../../../assets/Images/svg/topicsicon.svg';
import modulesIcon from '../../../assets/Images/svg/modulesicon.svg';
import aptitudetestIcon from '../../../assets/Images/svg/aptitudetesticon.svg';
import careerIcon from '../../../assets/Images/svg/careericon.svg';
import feedbacksIcon from '../../../assets/Images/svg/feedbacksicon.svg';

import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import { useCurrentUser } from 'common/context/CurrentUser';

interface Props { }

const TitleData = (props: Props) => {
  const classes = useStyles();
  const { currentUser } = useCurrentUser();
  const location = useLocation();

  let group: string = currentUser?.groups[0];

  console.log(location.pathname)

  return (
    <div>
      {config.roles.Admin === group && (
        <>
          <ListItem
            button
            component={NavLink}
            to={routes.dashboard.path}
            className={classes.selected}
            selected={location.pathname.startsWith('/dashboard')}
            exact
          >
            <img
              src={dashboardIcon}
              alt="edit"
              height="20"
              width="20"
              className="actions-img"
            />
            <ListItemText
              primary="Dashboard"
              className={classes.marginLeftStyleDashboard}
            />
          </ListItem>

          <ListItem
            button
            component={NavLink}
            to={routes.activityLogs.path}
            className={classes.selected}
            selected={location.pathname.startsWith('/activity-log')}
            exact
          >
            <img
              src={activitylogIcon}
              alt="edit"
              height="20"
              width="20"
              className="actions-img"
            />
            <ListItemText
              primary="Activity Logs"
              className={classes.marginLeftStyleActivityLogs}
            />
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to={routes.courses.path}
            className={classes.selected}
            selected={location.pathname.startsWith('/course')}
            exact
          >
            <img
              src={coursesIcon}
              alt="edit"
              height="20"
              width="20"
              className="actions-img"
            />
            <ListItemText
              primary="Courses"
              className={classes.marginLeftStyleCourses}
            />
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to={routes.cohort.path}
            className={classes.selected}
            selected={location.pathname.startsWith('/cohort')}
            exact
          >
            <img
              src={cohortIcon}
              alt="edit"
              height="20"
              width="21"
              className="actions-img"
            />
            <ListItemText
              primary="Cohorts"
              className={classes.marginLeftStyleCohorts}
            />
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to={routes.application.path}
            className={classes.selected}
            selected={location.pathname.startsWith('/application')}
            exact
          >
            <img
              src={applicationIcon}
              alt="edit"
              height="20"
              width="20"
              className="actions-img"
              style={{
                marginLeft: '1px',
              }}
            />
            <ListItemText
              primary="Applications"
              className={classes.marginLeftStyleApplications}
            />
          </ListItem>

          <ListItem
            button
            component={NavLink}
            to={routes.users.path}
            className={classes.selected}
            selected={location.pathname.startsWith('/user')}
            exact
          >
            <img
              src={usersIcon}
              alt="edit"
              height="20"
              width="20"
              className="actions-img"
            />
            <ListItemText
              primary="Users"
              className={classes.marginLeftStyleUsers}
            />
          </ListItem>

          <ListItem
            button
            component={NavLink}
            to={routes.groups.path}
            className={classes.selected}
            selected={location.pathname.startsWith('/custom-groups')}
            exact
          >
            <img
              src={groupsIcon}
              alt="edit"
              height="20"
              width="20"
              className="actions-img"
            />
            <ListItemText
              primary="Groups"
              className={classes.marginLeftStyleGroups}
            />
          </ListItem>

          <ListItem
            button
            component={NavLink}
            to={routes.assignments.path}
            className={classes.selected}
            selected={location.pathname.startsWith('/assignment')}
            exact
          >
            <img
              src={assignmentIcon}
              alt="edit"
              height="21"
              width="20"
              className="actions-img"
            />
            <ListItemText
              primary="Assignments"
              className={classes.marginLeftStyleAssignments}
            />
          </ListItem>

          <ListItem
            button
            component={NavLink}
            to={routes.project.path}
            className={classes.selected}
            selected={location.pathname.startsWith('/project')}
            exact
          >
            <img
              src={projectIcon}
              alt="edit"
              height="20"
              width="20"
              className="actions-img"
            />
            <ListItemText
              primary="Projects"
              className={classes.marginLeftStyleProjects}
            />
          </ListItem>

          <ListItem
            button
            component={NavLink}
            to={routes.skills.path}
            className={classes.selected}
            selected={location.pathname.startsWith('/skill')}
            exact
          >
            <img
              src={skillsIcon}
              alt="edit"
              height="22"
              width="20"
              className="actions-img"
            />
            <ListItemText
              primary="Skills"
              className={classes.marginLeftStyleSkills}
            />
          </ListItem>

          <ListItem
            button
            component={NavLink}
            to={routes.topics.path}
            className={classes.selected}
            selected={location.pathname.startsWith('/topic')}
            exact
          >
            <img
              src={topicsIcon}
              alt="edit"
              height="20"
              width="20"
              className="actions-img"
            />
            <ListItemText
              primary="Topics"
              className={classes.marginLeftStyleTopics}
            />
          </ListItem>

          <ListItem
            button
            component={NavLink}
            to={routes.module.path}
            className={classes.selected}
            selected={location.pathname.startsWith('/module')}
            exact
          >
            <img
              src={modulesIcon}
              alt="edit"
              height="21"
              width="20"
              className="actions-img"
            />
            <ListItemText
              primary="Modules"
              className={classes.marginLeftStyleModules}
            />
          </ListItem>

          <ListItem
            button
            component={NavLink}
            to={routes.aptitudeTest.path}
            className={classes.selected}
            selected={location.pathname.startsWith('/aptitude-test')}
            exact
          >
            <img
              src={aptitudetestIcon}
              alt="edit"
              height="21"
              width="20"
              className="actions-img"
            />
            <ListItemText
              primary="Aptitude Test"
              className={classes.marginLeftStyleAptitudeTest}
            />
          </ListItem>

          <ListItem
            button
            component={NavLink}
            to={routes.career.path}
            className={classes.selected}
            selected={location.pathname.startsWith('/job')}
            exact
          >
            <img
              src={careerIcon}
              alt="edit"
              height="20"
              width="20"
              className="actions-img"
            />
            <ListItemText
              primary="Career"
              className={classes.marginLeftStyleCareer}
            />
          </ListItem>

          <ListItem
            button
            component={NavLink}
            to={routes.feedbacks.path}
            className={classes.selected}
            selected={location.pathname.startsWith('/feedback')}
            exact
          >
            <img
              src={feedbacksIcon}
              alt="edit"
              height="20"
              width="20"
              className="actions-img"
            />
            <ListItemText
              primary="Feedbacks"
              className={classes.marginLeftStyleFeedbacks}
            />
          </ListItem>
        </>
      )}
      {config.roles.Creator === group && (
        <>
          <ListItem
            button
            component={NavLink}
            to={routes.questions.path}
            className={classes.selected}
            selected={location.pathname.startsWith('topic-question')}
            exact
          >
            <LiveHelpOutlinedIcon />
            <ListItemText
              primary="Questions"
              className={classes.marginLeftStyle}
            />
          </ListItem>

          <ListItem
            button
            component={NavLink}
            to={routes.videos.path}
            className={classes.selected}
            selected={location.pathname.startsWith('video')}
            exact
          >
            <VideocamOutlinedIcon />
            <ListItemText
              primary="Videos"
              className={classes.marginLeftStyle}
            />
          </ListItem>

          <ListItem
            button
            component={NavLink}
            to={routes.documents.path}
            className={classes.selected}
            selected={location.pathname.startsWith('document')}
            exact
          >
            <AssignmentOutlinedIcon />
            <ListItemText
              primary="Documents"
              className={classes.marginLeftStyle}
            />
          </ListItem>

          <ListItem
            button
            component={NavLink}
            to={routes.articles.path}
            className={classes.selected}
            selected={location.pathname.startsWith('article')}
            exact
          >
            <DescriptionOutlinedIcon />
            <ListItemText
              primary="Articles"
              className={classes.marginLeftStyle}
            />
          </ListItem>
        </>
      )}
    </div>
  );
};

export default TitleData;
