import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import MenuIcon from '@material-ui/icons/Menu';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, {
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state';

import Logo from 'assets/Images/svg/levelupLogo.svg';
import LogoWhite from 'assets/Images/svg/levelupwhite.svg';

import { useCurrentUser } from '../../context/CurrentUser';
import { useSkillContext } from '../../context/Skill';
import { removeToken } from '../../utils/token';
import config from '../../../config';
import routes from '../../../config/routes';
import ChangePassword from '../../../pages/Users/ChangePassword';
import FormDialog from '../Modal/FormDialog';
import SuccessDialog from '../SuccessDialog';
import { useStyles } from './styles';

interface Props {}

const Header = (props: Props) => {
  const classes = useStyles();
  const { currentUser, setValues } = useCurrentUser();
  const { setSkillValue } = useSkillContext();
  const history = useHistory();
  const [show, setShow] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleLogout = () => {
    removeToken({ name: config.tokenName });
    setValues({
      username: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      groups: [],
      profile_picture: '',
      email: '',
      display_name: '',
    });
    setSkillValue([]);
    history.push(routes.login.path);
  };
  const handleProfile = () => {
    history.push(routes.profile.path(currentUser?.username));
  };
  return (
    <AppBar
      position="absolute"
      className={`${classes.appBar} ${
        currentUser?.groups?.[0] === 'Creator' &&
        classes.appBarCreator
      }`}
      color="inherit"
    >
      <Toolbar disableGutters>
        <img
          className={classes.logo}
          src={
            currentUser?.groups?.[0] === 'Creator' ? LogoWhite : Logo
          }
          alt=""
          style={{ width: '15rem' }}
        />
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          className={classes.hide}
        >
          <MenuIcon />
        </IconButton>
        <Grid container>
          <Grid item xs={8} />
          <Hidden smDown>
            <Grid item xs></Grid>
          </Hidden>
        </Grid>

        {/* start */}
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <>
              <IconButton {...bindTrigger(popupState)}>
                <Avatar
                  alt="Profile"
                  src={currentUser?.profile_picture}
                />
              </IconButton>
              <div className={classes.profileDiv}>
                <span
                  {...bindTrigger(popupState)}
                  style={{ cursor: 'pointer' }}
                >
                  {currentUser?.groups?.[0]}
                </span>
                <KeyboardArrowDownIcon
                  {...bindTrigger(popupState)}
                  className={
                    currentUser?.groups?.[0] === 'Creator'
                      ? classes.whiteArrow
                      : ''
                  }
                />
              </div>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={handleProfile}>Profile</MenuItem>
                <MenuItem
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  Change Password
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </>
          )}
        </PopupState>
        {/* end */}
      </Toolbar>
      <FormDialog
        title="Change Password"
        show={show}
        setShow={setShow}
      >
        <ChangePassword
          setShow={setShow}
          setShowPassword={setShowPassword}
        />
      </FormDialog>
      <SuccessDialog
        title="Password Changed Successfully"
        showSuccess={showPassword}
        setShowSuccess={setShowPassword}
      />
    </AppBar>
  );
};

export default Header;
