export const HOME = '/';
export const LOGIN = '/login';
export const RESET = (email: string) => `/reset-password/${email}`;
export const ACTIVATEWITHCODE = (userCode: string, code: string) =>
  `/user/activate/${userCode}/${code}/`;
export const ACTIVATE = (activation_token: string) =>
  `/user/${activation_token}/activate/`;
export const DASHBOARD = '/dashboard';
export const COURSES = '/courses';
export const activityLog = '/activity-log';
export const ADDCOHORT = '/cohort/create';
export const COHORT = '/cohorts';
export const USERS = '/users';
export const GROUPS = '/custom-groups';
export const ASSIGNMENTS = '/assignments';
export const ASSIGNMENTADD = '/assignment/add';
export const ASSIGNMENT_DETAIL = (id: string) =>
  `/assignment/${id}/view`;
export const PROJECT = '/projects';
export const PROJECT_DETAIL = (id: string) => `/project/${id}/view`;
export const SKILLS = '/skills';
export const SKILL_DETAIL = (slug: string) => `/skill/${slug}`;
export const TOPICS = '/topics';
export const QUESTIONS = '/topic-questions';
export const CREATE_QUESTION = '/topic-question/create';
export const EDIT_QUESTION = (id: string) =>
  `/topic-question/${id}/edit`;
export const VIEW_QUESTION = (id: string) =>
  `/topic-question/${id}/view`;
export const VIDEOS = '/videos';
export const ADDVIDEOS = '/videos/add';
export const DOCUMENTS = '/documents';
export const MARKETING_CONTENT = '/marketing-contents'
export const ADDDOCUMENTS = '/documents/add';
export const ARTICLES = '/articles';
export const ADDARTICLES = '/articles/add';
export const FEEDBACKS = '/feedbacks';
export const ASSIGNMENTEDIT = (id: string) =>
  `/assignment/${id}/edit`;

export const CAREER = '/job-postings';
export const CREATECAREER = '/job/add';
export const CAREERDETAIL = (id: string) => `/job/${id}`;
export const JOBAPPLICATION = (id: string) =>
  `/job/${id}/application`;
export const CAREEREDIT = (id: string) => `/job/${id}/edit`;
export const ADD_CONTENT_QUESTION = (id: string) =>
  `/content/${id}/question/create`;
export const EDIT_CONTENT_QUESTION = (question_id: string) =>
  `/content/question/${question_id}/edit`;
export const VIDEOITEM = (id: string) => `/video/${id}/`;
export const VIDEOQUESTIONS = (id: string) =>
  `/video/${id}/questions/`;
export const DOCUMENTITEM = (id: string) => `/document/${id}/`;
export const DOCUMENTQUESTIONS = (id: string) =>
  `/document/${id}/questions/`;
export const ARTICLEITEM = (id: string) => `/article/${id}/`;
export const ARTICLEQUESTIONS = (id: string) =>
  `/article/${id}/questions/`;
export const VIEWCOURSE = (id: string) => `/course/${id}/view`;
export const EDITCOHORT = (id: string) => `/cohort/${id}/edit`;
export const COHORTPLANNER = (id: string) => `/cohort/${id}/planner`;
export const VIEWCOHORT = (id: string) => `/cohort/${id}/view`;
export const EDITPROJECT = (id: string) => `/project/${id}/edit`;
export const ADDPROJECT = `/project/create`;
export const EDITCONTENT = (id: string) => `/content/${id}/edit`;
export const APPLICATION = `/applications`;
export const MODULES = `/modules`;
export const ADD_MODULE = `/modules/add`;
export const MODULE_EDIT = (id: string) => `/module/${id}/edit`;
export const MODULE_CONTENT_EDIT = (id: string) =>
  `/module/${id}/content/edit`;
export const MODULE_DETAIL = (id: string) => `/module/${id}/view`;
export const APPLICATION_DETAIL = (courseUuid: string, id: string) =>
  `/applications/${id}/course/${courseUuid}/view`;
export const APTITUDE_QUESTION = '/aptitude-questions';
export const ADD_APTITUDE_QUESTION = `/aptitude-questions/add`;
export const APTITUDE_QUESTION_EDIT = (id: string) =>
  `/aptitude-questions/${id}/edit`;
export const APTITUDE_QUESTION_DETAIL = (id: string) =>
  `/aptitude-questions/${id}/view`;
export const PROFILE = (id: string) => `/profile/${id}`;
export const EDIT_PROFILE = (id: string) => `/profile/edit/${id}`;
export const ADD_APTITUDE_TEST = `/aptitude-test/add`;
export const APTITUDE_TEST = '/aptitude-test';
export const APTITUDE_TEST_EDIT = (id: string) =>
  `/aptitude-test/${id}/edit`;
export const APTITUDE_TEST_DETAIL = (id: string) =>
  `/aptitude-test/${id}/view`;
