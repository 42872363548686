import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { Toaster } from 'react-hot-toast';
import { CurrentUserProvider } from 'common/context/CurrentUser';
import { SkillProvider } from '../common/context/Skill';
import Routes from './routes';
import { toastStyle } from './toaststyle';

const font = '"Poppins", sans-serif';

const theme = createTheme({
  typography: {
    fontFamily: font,
  },
  palette: {
    primary: {
      main: '#FB6E00 !important',
    },
    secondary: {
      main: '#A8A8A8 !important',
      light: '#F8F8F8 !important',
      dark: '#808080 !important',
    },
    // action: {
    //   disabledBackground: '#808080 ',
    //   disabled: '#808080'
    // }
  },
});

const App = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <CurrentUserProvider>
          <SkillProvider>
            <Routes />
          </SkillProvider>
        </CurrentUserProvider>
      </ThemeProvider>
      <Toaster position="bottom-center" toastOptions={toastStyle} />
    </>
  );
};

export default App;
