import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    passwordVisibility: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    formInputStyle: {
      '& input': {
        height: '48px !important',
        padding: '0',
      },
    },
  }),
);
