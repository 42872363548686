import Http from './Http';

const http = new Http();

export default class SkillsService {
  static getSkills(apiURL: string, filter: any) {
    return http.get<any>({
      endpoint: `/${apiURL}/heart/skills/`,
      payload: { params: filter },
    });
  }
  static retrieveSkill(apiURL: string, slug: any) {
    return http.get<any>({
      endpoint: `/${apiURL}/heart/skills/${slug}`,
    });
  }
  static getSkillURL(apiURL: string, slug: any) {
    return http.get<any>({
      endpoint: `/${apiURL}/heart/skills/${slug}/sharable-link/`,
    });
  }
  static getAutoCompleteSkills(apiURL: string, filter: any) {
    return http.get<any>({
      endpoint: `/${apiURL}/heart/skills/autocomplete/`,
      payload: { params: filter },
    });
  }

  static patchSkillVisibility(
    apiURL: string,
    slug: any,
    payload: any,
  ) {
    return http.patch<any>({
      endpoint: `/${apiURL}/heart/skills/${slug}/change-skill-public/`,
      payload: payload,
    });
  }

  static createSkill(apiURL: string, args: any) {
    return http.post<any>({
      endpoint: `/${apiURL}/heart/skills/`,
      payload: args,
    });
  }

  static patchSkillStatus(apiURL: string, uuid: string, args: any) {
    return http.patch<any>({
      endpoint: `/${apiURL}/bootcamp/course/${uuid}/skill-status/`,
      payload: args,
    });
  }

  static updateSkill(apiURL: string, skill_slug: string, args: any) {
    return http.patch<any>({
      endpoint: `/${apiURL}/heart/skills/${skill_slug}/`,
      payload: args,
    });
  }

  static updateUserSkills(
    apiURL: string,
    username: string,
    args: any,
  ) {
    return http.patch<any>({
      endpoint: `/${apiURL}/users/${username}/update-skill/`,
      payload: args,
    });
  }

  // static getQuestionCSV(apiURL: string) {
  //   return http.get<any>({
  //     endpoint: `/${apiURL}/assessment/get-newly-added-quesion-deails`,
  //   });
  // }

  static postQuestionCSV(apiURL: string, payload: any) {
    return http.post<any>({
      endpoint: `/${apiURL}/assessment/get-newly-added-question-details`,
      payload: payload,
    });
  }

  static assignedSkill(apiURL: string, username: string) {
    return http.get<any>({
      endpoint: `/${apiURL}/heart/skills/assigned/${username}/`,
    });
  }

  static getRelatedModules(apiURL: string, slug: string) {
    return http.get<any>({
      endpoint: `/${apiURL}/heart/skills/${slug}/related-modules/`,
    });
  }
}
