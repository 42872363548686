import { useEffect, Suspense, useState } from 'react';
import {
  Route,
  RouteProps,
  Redirect,
  useHistory,
} from 'react-router-dom';
import _ from 'lodash';

import routes from 'config/routes';
import NavBar from 'common/components/NavBar';
import Header from 'common/components/Header';
import { useCurrentUser } from 'common/context/CurrentUser';

import auth from 'config/auth';
import { IRouteProps } from 'common/interfaces/IAuth';
import { useStyles } from './style';
import Auth from '../../../services/UserService';
import { Levelup } from 'common/constants/api';
import SkillsService from '../../../services/SkillsService';
import { useSkillContext } from '../../../context/Skill';

const AuthenticatedRoute = ({
  Component,
  path,
  exact = false,
  roles,
}: IRouteProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isAuthed = auth.isAuthenticated();
  const { currentUser, setValues } = useCurrentUser();
  const { skillContext, setSkillValue } = useSkillContext();
  const history = useHistory();
  const classes = useStyles();

  let group: string = currentUser?.groups[0];

  useEffect(() => {
    if (isAuthed) {
      Auth.getUser(Levelup, 'me')
        .then((response) => {
          setValues({
            username: response?.username,
            first_name: response?.first_name,
            middle_name: response?.middle_name,
            last_name: response?.last_name,
            groups: response?.groups,
            profile_picture: response?.profile_picture,
            email: response?.profile_picture,
            display_name: response?.display_name,
          });
        })
        .catch(() => {
          history.push(routes.login.path);
        });
    }
    // eslint-disable-next-line
  }, [isAuthed, history]);

  useEffect(() => {
    if (skillContext.length === 0) {
      const filters: any = { for_filter: true };
      SkillsService.getSkills(Levelup, filters).then((response) => {
        setSkillValue(response?.results);
      });
    }
    // eslint-disable-next-line
  }, []);

  if (isAuthed && _.isEmpty(currentUser)) {
    return null;
  }

  const handleToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const userHasRequiredRole =
    isAuthed && roles && roles.includes(group);

  return (
    <div className={classes.root}>
      <Header />
      <NavBar
        isDrawerOpen={isDrawerOpen}
        handleToggleDrawer={handleToggleDrawer}
      />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Suspense fallback={<div>Loading...</div>}>
          <Route
            exact={exact}
            path={path}
            render={(props: RouteProps) =>
              userHasRequiredRole ? (
                <Component {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: isAuthed
                      ? routes.courses.path
                      : routes.login.path,
                    state: {
                      requestedPath: path,
                    },
                  }}
                />
              )
            }
          />
        </Suspense>
      </main>
    </div>
  );
};

export default AuthenticatedRoute;
