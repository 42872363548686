import { useEffect, Suspense } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { getToken } from 'common/utils/token';
import config from 'config';
import routes from 'config/routes';
interface IProps extends RouteProps {}

const UnAuthenticatedRoute = (props: IProps) => {
  const history = useHistory();
  const token = getToken({ name: config.tokenName });
  const path = window.location.pathname;
  useEffect(() => {
    if (
      !path.includes('reset-password') &&
      !path.includes('activate')
    ) {
      if (!token) {
        history.push(routes.login.path);
      }
    }
  }, [history, token, path]);

  if (token) {
    history.push(routes.home.path);
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Route {...props} />
    </Suspense>
  );
};

export default UnAuthenticatedRoute;
