const config = {
  apiUrl: process.env.REACT_APP_BASE_URL,
  tokenName: 'token',
  roles: {
    Admin: 'Admin',
    Creator: 'Creator',
  },
  reactLimit: process.env.REACT_APP_LIMIT || 20,
};

export default config;