import { Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import styles from './style.module.scss';

interface Props {
  children: React.ReactNode;
  type: 'submit' | 'add' | 'cancel';
  variant: 'primary' | 'secondary' | 'dark' | '';
  showIcon?: boolean;
  onClick?: () => void;
  kind?: 'submit' | 'button';
  disabled?: boolean;
  hidden?: boolean;
  classes?: any;
  className?: string;
}

const CustomButton = (props: Props) => {
  const {
    children,
    type,
    variant,
    showIcon,
    onClick,
    kind = 'button',
    disabled = false,
    hidden = false,
    classes,
    className,
  } = props;
  return (
    <Button
      type={kind}
      onClick={onClick}
      disabled={disabled}
      hidden={hidden}
      className={` ${styles[type]} ${styles.btn} ${styles[variant]} ${classes} ${className}`}
    >
      {showIcon && <AddCircleIcon />} &nbsp;
      {children}
    </Button>
  );
};

export default CustomButton;
