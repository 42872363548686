import {
    createContext,
    useState,
    useContext,
    ReactChild,
} from 'react';

import {ISkill} from "../interfaces/ISkill";

const Skill = createContext({});

interface IProps {
    children: ReactChild;
}

export const SkillProvider = ({ children }: IProps) => {
    const [skillContext, setSkillContext] = useState<ISkill[]>([]);

    const setSkillValue = (args: []) => {
        setSkillContext(
            args.map((item: any) => {
                return {
                    value: item.slug,
                    label: item.name,
                };
            }),
        );
    };

    return (
        <Skill.Provider
            value={{
                skillContext,
                setSkillValue,
            }}
        >
            {children}
        </Skill.Provider>
    );
};

export const useSkillContext: any = () => useContext(Skill);