import { makeStyles, createStyles, Theme } from '@material-ui/core';
const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: {
      [theme.breakpoints.up('md')]: {
        position: 'relative',
      },
      borderRight: '1px solid ##A8A8A8',
      marginTop: '64px',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperCreator: {
      [theme.breakpoints.up('md')]: {
        position: 'relative',
      },
      borderRight: '1px solid ##A8A8A8',
      marginTop: '64px',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      backgroundColor: '#373D57',
      color: 'white',
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    toolbar: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      boxSizing: 'border-box',
      ...theme.mixins.toolbar,
    },
    pageTitleContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: `0 0 0 35px`,
    },
    pageTitleTypography: {
      color: theme.palette.text.secondary,
      fontSize: '24px',
    },
    activeSideNav: {
      color: theme.palette.primary.main,
    },
    selected: {
      '&.Mui-selected': {
        backgroundColor: 'transparent',
      filter:'invert(65%) sepia(69%) saturate(5643%) hue-rotate(2deg) brightness(100%) contrast(100%)',
     
      },
    },
    sideNavIcons: {
      color: theme.palette.secondary.main,
    },
    marginLeftStyleDashboard: {
      marginLeft: '20px',
    },
    marginLeftStyleActivityLogs: {
      marginLeft: '21px',
    },
    marginLeftStyleCourses: {
      marginLeft: '20px',
    },
    marginLeftStyleCohorts: {
      marginLeft: '20px',
    },
    marginLeftStyleApplications: {
      marginLeft: '20px',
    },
    marginLeftStyleUsers: {
      marginLeft: '20px',
    },
    marginLeftStyleGroups: {
      marginLeft: '20px',
    },
    marginLeftStyleAssignments: {
      marginLeft: '21px',
    },
    marginLeftStyleProjects: {
      marginLeft: '20px',
    },
    marginLeftStyleSkills: {
      marginLeft: '20px',
    },
    marginLeftStyleTopics: {
      marginLeft: '20px',
    },
    marginLeftStyleModules: {
      marginLeft: '20px',
    },
    marginLeftStyleAptitudeTest: {
      marginLeft: '21px',
    },
    marginLeftStyleCareer: {
      marginLeft: '20px !important',
    },
    marginLeftStyleFeedbacks: {
      marginLeft: '20px',
    },
    marginLeftStyle: {
      marginLeft: '20px',
    },
  }),
);

export { useStyles };
