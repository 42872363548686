import { makeStyles, createStyles, Theme } from '@material-ui/core';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
      borderBottom: '1px solid #A8A8A8',
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      boxShadow: 'none',
    },
    appBarCreator: {
      backgroundColor: '#4E567A',
      color: 'white',
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 36,
    },
    hide: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    logo: {
      marginLeft: 15,
    },
    whiteArrow: {
      color: 'white',
	},
	profileDiv:{
	display: 'inline-flex',
    marginRight: '1rem',
	cursor:'pointer'
	}  
}),
);

export { useStyles };
